
import React from 'react'
import { paymentService, cookieService } from '../services';
import ClipLoader from 'react-spinners/ClipLoader'
import { rootConfig } from '../../../../shared/config'
import Layout from '../components/layout/components/Layout'
import Input from '../components/shared/input/components/Input';
import Button from '../components/shared/button/components/Button';

let jsPDF = null; 
if (typeof window != 'undefined') {
  jsPDF = require('jspdf')
}

const details = {
  name: "Khalil Stemmler",
  officeAddress: {
    street: "1360 White Oaks Blvd",
    city: "Oakville",
    postal: "ON, L6H 2R7",
    country: "Canada"
  },
  email: "khalil@khalilstemmler.com",
  site: "solidbook.io"
}

class InvoiceContainer extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      checkingIfPaid: true,
      hasPaid: false,
      hash: '',
      fullName: '',
      streetAddress: '',
      city: '',
      stateOrProvince: '',
      postalCode: '',
      country: '',
    }
  }

  updateFormField (fieldName, value) {
    this.setState({
      ...this.state,
      [fieldName]: value
    })
  }

  getBookPrice () {
    return rootConfig.price;
  }

  async checkIfPaid () {
    let paid = false;
    const hasHashInUrlIndex = window.location.href.indexOf('hash');
    const hasHashInUrl = hasHashInUrlIndex !== -1;
    let hash = null;

    hash = cookieService.getHashFromCookie();

    if (hasHashInUrl) {
      hash = window.location.href.substring(hasHashInUrlIndex + 5);
    }

    if (hash !== null && hash !== undefined) {
      paid = await paymentService.checkPaid(hash);
    }

    if (paid) {
      cookieService.saveHashAsCookie(hash);
      this.setState({ ...this.state, checkingIfPaid: false, hasPaid: true, hash })
    } else {
      cookieService.removeCookie();
      this.setState({ ...this.state, checkingIfPaid: false, hasPaid: false })
    }
  }

  componentDidMount () {
    this.checkIfPaid();
  }

  isFormReady () {
    const { 
      fullName, 
      streetAddress, 
      city, 
      stateOrProvince, 
      postalCode,
      country,
    } = this.state;

    if (!!fullName === false) {
      alert('You might have forgotten "full name"')
      return false;
    }

    if (!!streetAddress === false) {
      alert('You might have forgotten "Street address"')
      return false;
    }

    if (!!city === false) {
      alert('You might have forgotten "city"')
      return false;
    }

    if (!!stateOrProvince === false) {
      alert('You might have forgotten "state"')
      return false;
    }

    if (!!postalCode === false) {
      alert('You might have forgotten "zip code"')
      return false;
    }

    if (!!country === false) {
      alert('You might have forgotten "country"')
      return false;
    }

    return true;

  }

  onDownloadPDF () {
    if (!this.isFormReady() && typeof window !== 'undefined') {
      return false;
    }

    var doc = new jsPDF()
    doc.setFontSize(18);
    doc.text("Khalil Stemmler", 20, 20);

    doc.setFontSize(14);
    doc.setFontStyle("bold");
    doc.text('Office address', 20, 30);

    doc.setFontStyle("normal");
    doc.text(details.officeAddress.street, 20, 40);
    doc.text(`${details.officeAddress.city}, ${details.officeAddress.postal}`, 20, 50);
    doc.text(details.officeAddress.country, 20, 60);

    doc.setFontStyle("bold");
    doc.text('Email', 20, 90);

    doc.setFontStyle("normal");
    doc.text(details.email, 20, 100);

    doc.setFontStyle("bold");
    doc.text('Web', 20, 110);

    doc.setFontStyle("normal");
    doc.text(details.site, 20, 120);

    doc.line(20, 130, 80, 130);

    doc.setFontSize(24);
    doc.setFontStyle("bold");
    doc.text('Invoice', 100, 20);

    doc.setFontSize(14);
    doc.setFontStyle("bold");
    doc.text('Date', 100, 30);

    doc.setFontStyle("normal");
    doc.text(new Date().toDateString(), 100, 40);

    doc.setFontStyle("bold");
    doc.text('Order ID', 100, 50);

    doc.setFontStyle("normal");
    doc.text(this.state.hash, 100, 60); // TODO:

    doc.setFontStyle("bold");
    doc.text('To', 100, 70);

    doc.setFontStyle("normal");
    doc.text(this.state.fullName, 100, 80); 
    doc.text(this.state.streetAddress, 100, 90); 
    doc.text(`${this.state.city}, ${this.state.stateOrProvince} ${this.state.postalCode}`, 100, 100); 
    
    doc.setFontStyle("bold");
    doc.text('Item purchased', 100, 110);

    doc.setFontStyle("normal");
    doc.text("Solid Book", 100, 120); 

    doc.setFontStyle("bold");
    doc.text('Transaction amount', 100, 130);

    doc.setFontStyle("normal");
    doc.text(`$${this.getBookPrice()}`, 100, 140); 

    doc.setFontStyle("bold");
    doc.text('Quantity', 100, 150);

    doc.setFontStyle("normal");
    doc.text("1", 100, 160);

    doc.save(`solid-book-invoice-${new Date().toDateString()}.pdf`)
  }

  render () {
    return (
      <Layout>
        {this.state.checkingIfPaid ? (
          <div className="invoice-loader-container">
            <ClipLoader
              color="#7DE77B"
              size={100}
              loading={true}
            />
          </div>
        ) : this.state.hasPaid === false ? (
          <div className="invoice-page-container">
            <div className="invoice-container">
              <div>Hmm, it doesn't look like you have access to this.</div>
              <br/>
              <div>Did you purchase the book? </div>
              <br/>
              <div>If <b>yes</b>, go <a href="/">home</a> and use the button to send another copy of the book to your email. You should be able to generate your invoice from a fresh link.</div>
              <br/>
              <div>If <b>no</b>, consider <a href="/">buying the book</a> :)</div>
            
            </div>
          </div>
        ) : (
          <div className="invoice-page-container">
            <div className="invoice-container">
              <p>Generate invoice for...</p>
              <h2>SOLID Book</h2>
              <div className="invoice-input-container">
                <Input 
                  placeholder="Full name"
                  onChange={(e) => this.updateFormField('fullName', e.target.value)}
                />
                <Input 
                  placeholder="Street address"
                  onChange={(e) => this.updateFormField('streetAddress', e.target.value)}
                />
                <div className="flex">
                  <Input 
                    placeholder="City"
                    onChange={(e) => this.updateFormField('city', e.target.value)}
                  />
                  <Input 
                    placeholder="State"
                    onChange={(e) => this.updateFormField('stateOrProvince', e.target.value)}
                  />
                  <Input 
                    placeholder="Zip"
                    onChange={(e) => this.updateFormField('postalCode', e.target.value)}
                  />
                </div>
                <Input 
                  placeholder="Country"
                  onChange={(e) => this.updateFormField('country', e.target.value)}
                />
              </div>

              <br/>

              <div className="reciept-container">
                <div className="column">
                  <p>{details.name}</p>

                  {/* Office address */}
                  <p><b>Office address</b></p>
                  <p>{details.officeAddress.street}</p>
                  <p>{details.officeAddress.city}</p>
                  <p>{details.officeAddress.postal}</p>
                  <p>{details.officeAddress.country}</p>

                  {/* Hash */}
                  <p><b>ID</b></p>
                  <p>{this.state.hash}</p>

                  {/* Email */}
                  <p><b>Email</b></p>
                  <p>{details.email}</p>

                  <p><b>Web</b></p>
                  <p>{details.site}</p>

                </div>
                <div className="column">
                  <h5>Receipt</h5>

                  <p><b>Date</b></p>
                  <p>{new Date().toDateString()}</p>

                  <p><b>To</b></p>
                  <p>{this.state.fullName}</p>
                  <p>{this.state.streetAddress}</p>
                  <p>{this.state.city}, {this.state.stateOrProvince} {this.state.postalCode}</p>
                  <p>{this.state.country}</p>

                  <p><b>Item purchased</b></p>
                  <p>Solid Book</p>

                  <p><b>Price</b></p>
                  <p>${this.getBookPrice()}</p>
                </div>
              </div>

              <br/>

              <Button text="Download" onClick={() => this.onDownloadPDF()}/>
              <br/>
              <br/>
              <p>This invoice will downloaded as a PDF to your computer.</p>
            </div>

            <br/>
            <br/>
            
          </div>
        )}
      </Layout>
    )
  }
}

export default InvoiceContainer;
